import { useEffect } from "react";
import { Col, Row, Spin } from "antd";
import { getAccessToken } from "@/utils/index.js";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import Home from "@/pages/Home/index.jsx";
import { CLIENT_ID } from "@/constants/index.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useUser from "@/store/useUser.js";
import useCalendar from "@/store/useCalendar.js";
import useZones from "@/store/useZones.js";
import userService from "@/services/apiServices/userService/index.js";
import mixpanel from "mixpanel-browser";
import { isMixpanelInitialized } from "./mixpanelHelper";

function App() {
  const { data: userData, isLoading: isUserInfoLoading } = useUser();
  const { data: zoneData } = useZones();
  const accessToken = getAccessToken();
  const location = useLocation();
  const navigate = useNavigate();
  const [, setIsReminderCategoryVisible] = useLocalStorage("reminder", true);
  const [, setIsReminderRevokedCalendarVisible] = useLocalStorage(
    "reminder_revoked_calendar",
    true,
  );
  const [, setIsReminderEventVisible] = useLocalStorage("event_reminder", true);
  const [, setIsAudio] = useLocalStorage("audio", true);

  // set calendar settings
  const [selectedCalendarIds] = useLocalStorage("selectedCalendarIds", {
    calendarIds: [],
    subCalendarIds: [],
  });
  const setCalendarIds = useCalendar((store) => store.setCalendarIds);

  useEffect(() => {
    if (
      zoneData?.data &&
      zoneData?.data?.zones?.length >= 0 &&
      userData?.data?.settings &&
      !userData?.data?.settings?.zone_ids?.length
    ) {
      userService.updateSettings({
        zone_ids: zoneData?.data?.zones?.map((zone) => zone.id),
      });
    }
  }, [zoneData, userData]);

  useEffect(() => {
    setCalendarIds({ ...selectedCalendarIds });
  }, [selectedCalendarIds, setCalendarIds]);

  useEffect(() => {
    setIsReminderCategoryVisible(true);
    setIsReminderEventVisible(true);
    setIsReminderRevokedCalendarVisible(true);
    setIsAudio(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!accessToken || accessToken?.length === 0) {
      navigate("/login");

      if (isMixpanelInitialized()) {
        mixpanel.reset();
      }
    } else if (userData?.data) {
      if (
        accessToken &&
        (!userData?.data?.gcal_connected || !userData?.data?.onboarded) &&
        location.pathname !== "/login"
      ) {
        navigate("/login");

        if (isMixpanelInitialized()) {
          mixpanel.reset();
        }
      }
      if (
        accessToken &&
        userData?.data?.gcal_connected &&
        userData?.data?.onboarded &&
        location.pathname === "/"
      ) {
        navigate("/overview");
      }
    }
  }, [accessToken, userData, location.pathname, navigate]);

  if (isUserInfoLoading)
    return (
      <Row className="h-screen justify-center items-center w-full">
        <Spin />
      </Row>
    );

  return (
    <Col className="h-screen w-full">
      <GoogleOAuthProvider
        clientId={
          import.meta.env.VITE_ACTIVE_ENV === "PRODUCTION"
            ? CLIENT_ID.PRODUCTION
            : CLIENT_ID.STAGING
        }
      >
        {!accessToken ||
        !userData?.data?.gcal_connected ||
        !userData?.data?.onboarded ||
        location.pathname === "/login" ||
        location.pathname.includes("profile") ? (
          <Outlet />
        ) : (
          <Home />
        )}
      </GoogleOAuthProvider>
    </Col>
  );
}

export default App;
