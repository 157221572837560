import mixpanel from "mixpanel-browser";

let isInitialized = false;

export const initializeMixpanel = (isDebug) => {
  mixpanel.init("615eff7811bde31a3ce37fb67a3a3681", {
    debug: isDebug,
    track_pageview: "full-url",
    persistence: "localStorage",
  });

  isInitialized = true;
};

export const isMixpanelInitialized = () => isInitialized;
