import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { SWRConfig } from "swr";
import appRouter from "@/router/index.jsx";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";

import { initializeMixpanel } from "./mixpanelHelper";

const swrConfig = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  // dedupingInterval: 900000,
};

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: "https://ac162e3294238fa3ba4260826aa03aa1@o4508483517677568.ingest.us.sentry.io/4508483521216512",
    integrations: [new BrowserTracing()],
    release: "v1",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });

  initializeMixpanel(false);
} else {
  // dev mode

  initializeMixpanel(true);
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <SWRConfig value={swrConfig}>
      <RouterProvider router={appRouter} />
    </SWRConfig>
  </React.StrictMode>,
);
