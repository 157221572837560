import { message } from "antd";

export const isMobile = () => (window.innerWidth > 1024 ? "desktop" : "mobile");

export const generateRandomNumericString = () => {
  const time = new Date().getTime();
  const rand1 = Math.random() * 100;
  const rand2 = Math.random() * 1000;
  const rand3 = Math.random() * 10000;
  const id = String(parseInt(`${time + rand1 + rand2 + rand3}`, 10));

  return id;
};

export const getCookieId = () => {
  let cookieId = window.localStorage.getItem("cookieId");
  if (!cookieId) {
    cookieId = generateRandomNumericString();
    window.localStorage.setItem("cookieId", cookieId);
  }
  return cookieId;
};

export const getLoginMethod = () => {
  const loginMethod = window.localStorage.getItem("loginMethod");
  if (loginMethod === "null") {
    return null;
  }
  return loginMethod;
};

const COOKIE_KEYS = {
  ACCESS_TOKEN: "Token",
};
export const getCookie = (name) => {
  const pattern = RegExp(`${name}=.[^;]*`);
  const matched = document?.cookie?.match(pattern);
  if (matched) {
    const cookie = matched[0].split("=");
    if (cookie[1] === "null") {
      return false;
    }
    return cookie[1] === "undefined" ? "" : cookie[1];
  }
  return "";
};
export const getAccessToken = () => {
  const parentalAccessToken = localStorage.getItem("parentalAccessToken");
  if (parentalAccessToken) return parentalAccessToken;

  return getCookie(COOKIE_KEYS.ACCESS_TOKEN);
};

export const setCookie = (name, value, days = 30) => {
  let date;
  let expires = "";
  if (days) {
    date = new Date();
    date.setDate(date.getDate() + days);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};
export const setAccessToken = (token) => setCookie(COOKIE_KEYS.ACCESS_TOKEN, token);

export const getLocalStorageKey = (key) => localStorage.getItem(key) ?? false;

export const groupBy = (values, keyFinder) => {
  // using reduce to aggregate values
  return values.reduce((a, b) => {
    // depending upon the type of keyFinder
    // if it is function, pass the value to it
    // if it is a property, access the property
    const key = typeof keyFinder === "function" ? keyFinder(b) : b[keyFinder];

    // aggregate values based on the keys
    if (!a[key]) {
      a[key] = [b];
    } else {
      a[key] = [...a[key], b];
    }

    return a;
  }, {});
};

export const getCategoryIdMap = (data, obj) => {
  data?.forEach((item) => {
    obj[item.id] = item;
    getCategoryIdMap(item?.childs, obj);
  });
  return obj;
};
export const getZoneIdMap = (data, obj) => {
  data?.forEach((item) => {
    obj[item.id] = item;
  });
  return obj;
};
export const getNestedCategoryFromId = (id, dataMap, arr) => {
  if (id === null) return arr;
  const name = dataMap?.[id]?.name || null;
  const parentId = dataMap?.[id]?.parent || null;
  return getNestedCategoryFromId(parentId, dataMap, [...arr, name]);
};

export const getCategoryName = (id, data) => {
  return (
    getNestedCategoryFromId(id, getCategoryIdMap(data, {}), [])?.reverse()?.join("/") ||
    ""
  );
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const copyTextToClipboard = (text, successMessage) => {
  if (!navigator.clipboard) {
    message.error("Error occured while copying");
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success(successMessage || "Copied successfully!");
    })
    .catch(() => {
      message.error("Error occured while copying");
    });
};

export const getDifferenceDate = (date1, date2) => {
  if (date1 && date2) {
    const diff = new Date(date1).getTime() - new Date(date2).getTime();
    let msec = diff;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    const ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    return [hh, mm, ss];
  }
  return [];
};

export const zeroPad = (num, places) => String(num)?.padStart(places, "0") || "";
export const fixDecimals = (num) => parseFloat(num)?.toFixed(1)?.replace(/\.0+$/, "");

export const getDate = (day, days) => {
  const date = new Date(day);
  date.setDate(date.getDate() + days);
  return new Date(date);
};

export const triggerFileOpen = (url) => {
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
