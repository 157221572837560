import { Button, Input, message } from "antd";
import { useEffect, useState } from "react";

const ParentalControl = () => {
  const [authTokenText, setAuthTokenText] = useState("");
  const [isLocalStroageKeyAvailable, setIsLocalStroageKeyAvailable] = useState(false);

  useEffect(() => {
    const parentalAccessToken = localStorage.getItem("parentalAccessToken");
    if (parentalAccessToken) {
      setIsLocalStroageKeyAvailable(true);
      setAuthTokenText(parentalAccessToken);
    }
  }, []);

  const onSubmit = () => {
    if (!authTokenText || authTokenText.trim().length == 0) {
      message.error("Access Token cannot be empty.");
      return;
    }

    localStorage.setItem("parentalAccessToken", authTokenText);

    window.location.reload();
  };

  const onClear = () => {
    localStorage.removeItem("parentalAccessToken");
    window.location.reload();
  };

  return (
    <div>
      <h1>Parental Control</h1>

      <div className="flex items-center gap-2">
        <div>Access&nbsp;Token:</div>
        <Input
          value={authTokenText}
          onChange={(e) => {
            setAuthTokenText(e.target.value);
          }}
          placeholder="Access token"
          disabled={isLocalStroageKeyAvailable}
        />
        {isLocalStroageKeyAvailable ? (
          <Button onClick={onClear}>Clear</Button>
        ) : (
          <Button onClick={onSubmit}>Submit</Button>
        )}
      </div>
    </div>
  );
};

export default ParentalControl;
