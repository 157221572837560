import { Avatar, Col, Dropdown, Row, Tooltip } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import Profile from "@/components/Profile/index.jsx";
import useUser from "@/store/useUser.js";
import ReminderCategory from "@/components/ReminderCategory/index.jsx";
import Nag from "@/assets/nag.svg";
import TimerHeader from "@/components/TimerHeader/index.jsx";
import useTimer from "@/store/useTimer.js";
import NagModalContainer from "@/components/NagModalContainer/index.jsx";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import ReminderCalendar from "@/components/ReminderCalendar/index.jsx";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { data: userData } = useUser();
  const user = userData?.data;
  const navigate = useNavigate();

  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenNag, setIsOpenNag] = useState(false);
  const [isVisible, setIsVisible] = useLocalStorage("event_reminder", false);
  const [isUnderParentalControl, setIsUnderParentalControl] = useState(false);

  useEffect(() => {
    const parentalAccessToken = localStorage.getItem("parentalAccessToken");
    if (parentalAccessToken) {
      setIsUnderParentalControl(true);
    }
  }, []);

  // new event timer
  const isNewTimerRunning = useTimer((store) => store.isRunning);

  return (
    <>
      <Row className="justify-between py-[30px]">
        <Col className="w-[90%]">
          <ReminderCategory />
          <ReminderCalendar />
        </Col>
        <Row className="flex-row-reverse py-[6px]">
          <Dropdown
            overlay={<Profile onSubmit={() => setIsOpenProfile(false)} />}
            trigger={["click"]}
            visible={isOpenProfile}
            onVisibleChange={() => setIsOpenProfile(!isOpenProfile)}
          >
            <Avatar className="m-0 p-0 cursor-pointer bg-primary">
              {user?.email?.[0]?.toUpperCase() || ""}
            </Avatar>
          </Dropdown>
          {isUnderParentalControl && (
            <Tooltip
              title="Under parental control"
              className="cursor-pointer mr-0.5 text-lg"
              onClick={() => {
                navigate("/profile/parental-control");
              }}
            >
              <UsergroupAddOutlined />
            </Tooltip>
          )}

          <Dropdown
            overlay={
              <NagModalContainer
                isModal={true}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                key={"nag"}
              />
            }
            trigger={["click"]}
            visible={isOpenNag}
            className="mx-[24px]"
            onVisibleChange={() => setIsOpenNag(!isOpenNag)}
          >
            <Nag className={"cursor-pointer"} />
          </Dropdown>
        </Row>
      </Row>
      <Row>
        <NagModalContainer
          isModal={false}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          key={"nag"}
        />
        {isNewTimerRunning && <TimerHeader />}
      </Row>
    </>
  );
};

export default Header;
