import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  SelectOutlined,
  TagOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import CategoryDropdown from "@/components/CategoryDropdown/index.jsx";
import Priority from "@/assets/priority.svg";
import Zone from "@/assets/zone.svg";
import { defaultPriorities } from "@/mocks/index.js";
import { useEffect, useMemo, useState } from "react";
import useLoading from "@/hooks/useLoading.js";
import insightServices from "@/services/apiServices/insightServices/index.js";
import dayjs from "dayjs";
import useSWR from "swr";
import eventBus from "@/utils/eventBus.js";
import { durationSlots, EVENT_BUS_EVENTS, monthL, week } from "@/constants/index.js";
import DaySlots from "@/components/DaySlots/index.jsx";
import SlotCalendarView from "@/components/SlotCalendarView/index.jsx";
// import Slots from "@/assets/slots.svg";
// import SlotsOff from "@/assets/slots_off.svg";
import clsx from "clsx";
import { copyTextToClipboard, validateEmail } from "@/utils/index.js";
import useUser from "@/store/useUser.js";
import Union from "@/assets/union.svg";
import useTimer from "@/store/useTimer.js";
import createDomPurify from "dompurify";
import ZoneDropdown from "@/components/ZoneDropdown/index.jsx";
import { useSearchParams } from "react-router-dom";
import useLocalStorage from "@/hooks/useLocalStorage.js";
const DOMPurify = createDomPurify(window);
const format = "HH:mm";

const TodoModal = ({
  isTodoModalVisible,
  onSubmit,
  todoId,
  defaultSubTasks,
  isFullPage = false,
}) => {
  const [todo, setTodo] = useState({
    id: null,
    title: null,
    duration: null,
    durationSlots: "custom",
    zone: null,
    zone_id: null,
    category_id: null,
    category: null,
    priority: null,
    scheduling_status: null,
    completion_status: null,
    scheduling_type: null,
    due_date: null,
    scheduling_link_type: "copy_link",
    user_scheduling_info: {
      start_time: null,
      end_time: null,
    },
    guest_scheduling_info: {
      email_body: "",
      email_subject: "",
      scheduling_link: "",
      event_data: {
        start_time: null,
        end_time: null,
      },
    },
    guest_email: null,
    behalf_user_email: null,
    isSlotOffered: false,
    scheduling_link: "",
  });

  const [searchParams] = useSearchParams();
  const [slotRange, setSlotRange] = useState("current_week");
  const [isCalendarView, setIsCalendarView] = useLocalStorage(
    "todo_calendar_view",
    false,
  );
  const [isShowMore, setIsShowMore] = useState(false);

  const [isExistingTask, setIsExistingTask] = useState(false);
  const [existingTaskSlotType, setExistingTaskSlotType] = useState("old_slot");
  const [newSlot, setNewSlot] = useState({
    text: "",
    date: null,
    startTime: null,
    endTime: null,
    calendarId: null,
  });

  const [scheduleType, setScheduleType] = useState("manually_schedule");
  const [manualSlot, setManualSlot] = useState({
    date: null,
    startTime: null,
    endTime: null,
  });

  // to-do timer
  const isRunning = useTimer((store) => store.isRunning);
  const isTodoTimerRunning = useTimer((store) => store.isTodoTimerRunning);
  const setIsTodoTimerRunning = useTimer((store) => store.setIsTodoTimerRunning);
  const setTimerTodo = useTimer((store) => store.setTimerTodo);
  const subtasks = useTimer((store) => store.subtasks);
  const setSubtasks = useTimer((store) => store.setSubtasks);
  const setTodoText = useTimer((store) => store.setText);
  const start = useTimer((store) => store.start);
  const [currentSubTasks, setCurrentSubTasks] = useState(defaultSubTasks || []);

  const { data: userData } = useUser();

  // guest send email modal
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [customGenerateLinkSlots, setCustomGenerateLinkSlots] = useState([]);

  const {
    data: todoData,
    isLoading: isTodoDataLoading,
    mutate: mutateTodoData,
  } = useSWR(todoId || todo?.id ? `getTodoItem/${todoId || todo?.id}` : null, () =>
    insightServices.getTodoItem(todoId || todo?.id),
  );

  const currentDuration = useMemo(
    () => (todo?.durationSlots === "custom" ? todo?.duration : todo?.durationSlots || 10),
    [todo],
  );

  const { data: todoSlotsData, isLoading: isTodoSlotsLoading } = useSWR(
    (todoId || todo?.id) && todo?.zone_id
      ? `getTodoSlots/${todo?.id}/${currentDuration}/${todo?.zone_id}/${todo?.category_id}/${todo?.priority}`
      : null,
    () =>
      insightServices.getTodoSlotsAndLink(
        todoId || todo?.id,
        currentDuration,
        todo?.zone_id,
        todo?.category_id,
        todo?.priority,
      ),
  );

  const { data: taskBlockers, isLoading: isTaskBlockerLoading } = useSWR(
    `fetchTaskBlockers`,
    insightServices.getTaskBlockers,
  );

  // Function to send the token to the parent window in case opened from chrome extension
  const sendMessageToParent = (value) => {
    const parentDomain = "*";

    if (window.opener) {
      window.opener.postMessage(
        { isSubmitDone: value },
        parentDomain, // Target origin of the parent window
      );
    }
  };

  useEffect(() => {
    const title = searchParams.get("title");
    const category_id = searchParams.get("category_id");
    const priority = searchParams.get("priority");
    const zone_id = searchParams.get("zone_id");
    const due_date = searchParams.get("due_date");
    const duration = searchParams.get("duration");
    if (title || category_id || priority || zone_id || due_date || duration) {
      setTodo({
        ...todo,
        ...(title && { title: title }),
        ...(category_id && { category_id: category_id }),
        ...(priority && { priority: priority }),
        ...(zone_id && { zone_id: zone_id }),
        ...(due_date && { due_date: due_date }),
        ...(duration && { duration: duration }),
      });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    return () => {
      setTodo({
        id: null,
        title: null,
        duration: null,
        durationSlots: "custom",
        zone: null,
        zone_id: null,
        category_id: null,
        category: null,
        priority: null,
        isSlotAvailable: false,
        scheduling_status: null,
        completion_status: null,
        scheduling_type: null,
        scheduling_link_type: "copy_link",
        user_scheduling_info: {
          start_time: null,
          end_time: null,
        },
        guest_scheduling_info: {
          body: "",
          subject: "",
        },
        guest_email: null,
        behalf_user_email: null,
        scheduling_link: "",
        due_date: null,
        event_id: null,
      });
      setCurrentSubTasks([]);
      setIsShowMore(false);
    };
  }, []);

  useEffect(() => {
    if (!todo?.id && !todoId) setIsCalendarView(false);
    if (todo?.id || todoId) setIsCalendarView(true);
    if (defaultSubTasks?.length > 0) setIsShowMore(true);
    // eslint-disable-next-line
  }, [todo, defaultSubTasks]);

  useEffect(() => {
    if (todoData) {
      setTodo({
        ...todo,
        ...todoData?.data,
      });
      setCurrentSubTasks(todoData?.data?.subtasks || []);
      if (!isTodoTimerRunning && !isRunning) {
        setTodoText(todoData?.data?.title);
        setSubtasks(todoData?.data?.subtasks || [...subtasks]);
        setTimerTodo({
          ...todo,
          ...todoData?.data,
        });
      }
    }
    if (defaultSubTasks?.length > 0) setCurrentSubTasks(defaultSubTasks || []);

    if (scheduleType === "manually_schedule") setIsExistingTask(false);
    else setIsExistingTask(todoData?.data?.duration <= 30 ? true : false);
    // eslint-disable-next-line
  }, [todoData, defaultSubTasks, scheduleType]);

  const { executeAction: handleSaveTodo, loading: isSaveTodoLoading } = useLoading(
    async (isCloseModal, isTodoTimer) => {
      if (!todo.title) {
        message.error("Todo name is required");
        return;
      }
      try {
        const payload = {
          title: todo.title,
          subtitle: todo.subtitle,
          ...((todo.duration || todo.durationSlots) && {
            duration:
              todo.durationSlots === "custom" ? todo.duration : todo.durationSlots,
          }),
          ...(todo.zone_id && { zone_id: todo.zone_id }),
          ...(todo.category_id && { category_id: todo.category_id }),
          ...(todo.priority && { priority: todo.priority }),
          ...(todo.due_date && { due_date: dayjs(todo.due_date).format("YYYY-MM-DD") }),
          subtasks:
            currentSubTasks?.map((task) => ({
              ...task,
              start_time: task.start_time ? dayjs(task.start_time)?.format() : "",
              end_time: task.end_time ? dayjs(task.end_time)?.format() : "",
            })) || [],
          ...(searchParams.get("projectId") &&
            searchParams.get("goalId") && {
              source: {
                project_id: searchParams.get("projectId"),
                goal_id: searchParams.get("goalId"),
              },
            }),
        };

        const data = await insightServices.createTodo(payload);

        setTodo({
          ...todo,
          ...data?.data,
        });
        setCurrentSubTasks(data?.data?.subtasks || [...currentSubTasks]);

        if (isTodoTimer) {
          start();
          setTimerTodo({ ...todo, ...data?.data });
          setIsTodoTimerRunning(true);
          setTodoText(data?.data?.title);
          setSubtasks(data?.data?.subtasks || [...currentSubTasks]);
          onSubmit?.();
        }

        await mutateTodoData();
        message.success("Todo created successfully.");

        if (isCloseModal) {
          eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
          eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_EVENT_REMINDERS, {});
          onSubmit?.();
        }
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      }
    },
  );

  const { executeAction: handleUpdateTodo, loading: isUpdateTodoLoading } = useLoading(
    async (isCloseModal, isNotTooltip) => {
      if (!todo.title) {
        message.error("Todo name is required");
        return;
      }

      try {
        const payload = {
          id: todo.id,
          title: todo.title,
          subtitle: todo.subtitle,
          ...((todo.duration || todo.durationSlots) && {
            duration:
              todo.durationSlots === "custom" ? todo.duration : todo.durationSlots,
          }),
          ...(todo.category_id && { category_id: todo.category_id }),
          ...(todo.zone_id && { zone_id: todo.zone_id }),
          ...(todo.priority && { priority: todo.priority }),
          ...(todo.due_date && { due_date: dayjs(todo.due_date).format("YYYY-MM-DD") }),
          subtasks:
            currentSubTasks?.map((task) => ({
              ...task,
              start_time: task.start_time ? dayjs(task.start_time)?.format() : "",
              end_time: task.end_time ? dayjs(task.end_time)?.format() : "",
            })) || [],
        };
        await insightServices.updateTodo(payload);

        if (
          scheduleType == "manually_schedule" &&
          manualSlot.startTime &&
          manualSlot.endTime
        ) {
          await insightServices.scheduleTodo({
            id: todo.id,
            user_scheduling_info: {
              start_time: manualSlot.startTime.format("YYYY-MM-DDTHH:mm:ss[Z]"),
              end_time: manualSlot.endTime.format("YYYY-MM-DDTHH:mm:ss[Z]"),
            },
          });
        } else if (
          todo?.user_scheduling_info?.start_time &&
          todo?.user_scheduling_info?.end_time &&
          !isExistingTask
        ) {
          await insightServices.scheduleTodo({
            id: todo.id,
            user_scheduling_info: {
              start_time: dayjs(todo.user_scheduling_info.start_time).format(
                "YYYY-MM-DDTHH:mm:ss[Z]",
              ),
              end_time: dayjs(todo.user_scheduling_info.end_time).format(
                "YYYY-MM-DDTHH:mm:ss[Z]",
              ),
            },
          });
        }
        if (todo?.event_id && isExistingTask) {
          await insightServices.scheduleTodoInBlock({
            id: todo?.id,
            event_id: todo?.event_id,
          });
        }
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_EVENT_REMINDERS, {});
        if (!isNotTooltip && isCloseModal) {
          message.success("Todo updated successfully.");
        }

        await mutateTodoData();
        if (isCloseModal) {
          onSubmit?.();

          setTimeout(() => {
            sendMessageToParent(true);
          }, 800);
        }
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      }
    },
  );

  const {
    executeAction: handleSendSchedulingLink,
    loading: isSendSchedulingLinkLoading,
  } = useLoading(async () => {
    try {
      if (!todo.guest_email) {
        message.error("Email id is required");
        return;
      }
      if (!validateEmail(todo.guest_email)) {
        message.error("Email id is not valid");
        return;
      }
      const payload = {
        guest_email: todo.guest_email,
        behalf_user_email: todo.behalf_user_email || userData?.data?.email,
      };
      await insightServices.sendTodoSchedulingLink(todo.id, payload);
      message.success("Scheduling link sent successfully.");
      setIsCopyModalVisible(false);
      onSubmit?.();
    } catch (e) {
      message.error(
        typeof e?.response?.data?.msg === typeof String()
          ? e.response.data.msg
          : "Something went wrong. Please try again in some time.",
      );
    }
  });

  const handleAssignTimeSlots = async () => {
    // if (!todo?.duration && todo?.durationSlots === "custom") {
    //   message.error("Todo Duration is required for time slots");
    //   return;
    // }
    // if (!todo?.zone_id) {
    //   message.error("Todo zone is required for time slots");
    //   return;
    // }
    todo?.id || todoId ? await handleUpdateTodo(true) : await handleSaveTodo();
  };

  const isSlotsVisible = useMemo(() => {
    return (
      (todo?.id || todoId || todoData?.data?.id) &&
      ((todoData?.data?.duration && todoData?.data?.zone_id) ||
        (todo?.durationSlots === "custom"
          ? todo?.duration
          : todo.durationSlots && todo?.zone_id))
    );
  }, [todoData, todo, todoId]);

  const getTime = (start_time) => {
    const day = dayjs(start_time).get("day");
    const date = dayjs(start_time).get("date");
    const month = dayjs(start_time).get("month");
    const key = week[day] + ", " + monthL[month] + " " + date;
    const time =
      dayjs(start_time).format("hh") +
      ":" +
      dayjs(start_time).format("mm") +
      " " +
      dayjs(start_time).format("a");
    return key + " - " + time;
  };

  const isNewGuestLink = useMemo(() => {
    return (
      todoData?.data?.guest_scheduling_info?.scheduling_link?.includes("None") ||
      !todoData?.data?.guest_scheduling_info?.scheduling_link
    );
  }, [todoData]);

  const { executeAction: handleGenerateLink, loading: isGenerateLinkLoading } =
    useLoading(async () => {
      try {
        let currentSlotRange;

        if (slotRange === "next_week") currentSlotRange = "RANGE_NEXT_7_DAYS";
        else if (slotRange === "after_next_week") currentSlotRange = "RANGE_AFTER_7_DAYS";
        else if (slotRange === "current_week") currentSlotRange = "RANGE_CURRENT_WEEK";

        let payload = {
          slots_mode: currentSlotRange,
        };

        if (customGenerateLinkSlots.length > 0) {
          payload = {
            slots_mode: "SPECIFIC_SLOTS",
            slots: customGenerateLinkSlots.map((slot) => ({
              start_time: dayjs(slot.start_time).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              end_time: dayjs(slot.end_time).format("YYYY-MM-DDTHH:mm:ss[Z]"),
            })),
          };
        }
        await handleUpdateTodo();
        const guestData = await insightServices.updateGuestLink(todo?.id, payload);
        setTodo({
          ...todo,
          guest_scheduling_info: {
            ...todo?.guest_scheduling_info,
            email_body: guestData?.data?.email_body,
            email_subject: guestData?.data?.email_subject,
          },
        });
        if (isNewGuestLink) await handleUpdateTodo();
        message.success(
          isNewGuestLink
            ? "Scheduling link generated successfully"
            : "Scheduling link updated successfully",
        );
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setCustomGenerateLinkSlots([]);
      }
    });

  // const { executeAction: handleAutoScheduleTodo, loading: isAutoScheduleLoading } =
  //   useLoading(async () => {
  //     if (todo.durationSlots === "custom" && !todo.duration) {
  //       message.info("Please select duration");
  //       return;
  //     } else if (!todo.durationSlots) {
  //       message.info("Please select duration");
  //       return;
  //     }
  //     if (!todo?.zone_id) {
  //       message.info("Please select zone");
  //       return;
  //     }
  //     try {
  //       await handleUpdateTodo(true, true);
  //       await insightServices.autoScheduleTodo(todo?.id || todoId);
  //       await handleUpdateTodo(true, true);
  //       await mutateTodoData();
  //       message.success("Todo Schedule successfully.");
  //     } catch (e) {
  //       message.error(
  //         typeof e?.response?.data?.msg === typeof String()
  //           ? e.response.data.msg
  //           : "Something went wrong. Please try again in some time.",
  //       );
  //     }
  //   });

  const handleAddSubtask = () => {
    const filterCurrentSubtasks = [...currentSubTasks];
    filterCurrentSubtasks.push({
      title: "",
      start_time: "",
      end_time: "",
      id: new Date().getTime(),
    });
    setCurrentSubTasks(filterCurrentSubtasks);
  };

  const handleDeleteSubtask = (id) => {
    let filterCurrentSubtasks = [...currentSubTasks];
    filterCurrentSubtasks = filterCurrentSubtasks.filter((task) => task.id !== id);
    setCurrentSubTasks(filterCurrentSubtasks);
  };

  const handleTextChange = (id, newText) => {
    let filterCurrentSubtasks = [...currentSubTasks];
    filterCurrentSubtasks = filterCurrentSubtasks.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          title: newText,
        };
      }
      return task;
    });
    setCurrentSubTasks(filterCurrentSubtasks);
  };

  const handleTodoStartTimer = async () => {
    if (!todo?.id && !todoId) {
      await handleSaveTodo(true, true);
    } else {
      start();
      setTodoText(todo?.title);
      setTimerTodo({ ...todo });
      setSubtasks(currentSubTasks);
      setIsTodoTimerRunning(true);
      onSubmit?.();
    }
  };

  const recommendedSlots = useMemo(() => {
    return (
      taskBlockers?.data?.blocks
        ?.filter((block) => block?.free_duration_in_mins >= todo?.duration)
        ?.sort(
          (a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime(),
        ) || []
    );
  }, [taskBlockers, todo?.duration]);

  const defaultSelectedAddCalendarValue = useMemo(() => {
    let defaultValue = "";
    userData?.data?.subcalendars_with_write_access?.forEach((calendar) => {
      if (calendar?.primary_scheduling === true) {
        defaultValue = calendar?.id;
      }
    });
    return defaultValue;
  }, [userData]);

  const {
    executeAction: handleScheduleTodoInSlot,
    loading: isScheduleTodoInSlotLoading,
  } = useLoading(async () => {
    try {
      if (existingTaskSlotType === "new_slot") {
        if (!newSlot?.text) {
          message.info("Please add new slot title");
          return;
        }

        await handleUpdateTodo();

        const payload = {
          summary: newSlot?.text,
          start_time: dayjs(todo?.user_scheduling_info?.start_time).format(
            "YYYY-MM-DDTHH:mm:ss[Z]",
          ),
          end_time: dayjs(todo?.user_scheduling_info?.end_time).format(
            "YYYY-MM-DDTHH:mm:ss[Z]",
          ),
          type: "task_block",
          subcalendar_id: newSlot?.calendarId ?? defaultSelectedAddCalendarValue,
        };
        const data = await insightServices.createCalendarEntry(payload);
        await insightServices.scheduleTodoInBlock({
          id: todo?.id,
          event_id: data?.data?.id,
        });
        await mutateTodoData();
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_EVENT_REMINDERS, {});
        message.success("Todo updated successfully.");
        onSubmit?.();

        setTimeout(() => {
          sendMessageToParent(true);
        }, 800);
      } else await handleUpdateTodo(true);
    } catch (e) {
      message.error(
        typeof e?.response?.data?.msg === typeof String()
          ? e.response.data.msg
          : "Something went wrong. Please try again in some time.",
      );
    }
  });

  useEffect(() => {
    if (newSlot.date && newSlot.startTime && newSlot.endTime) {
      setTodo({
        ...todo,
        user_scheduling_info: {
          start_time: dayjs(newSlot.date)
            .set("hour", dayjs(newSlot.startTime).get("hour"))
            .set("minute", dayjs(newSlot.startTime).get("minute")),
          end_time: dayjs(newSlot.date)
            .set("hour", dayjs(newSlot.endTime).get("hour"))
            .set("minute", dayjs(newSlot.endTime).get("minute")),
        },
      });
    }
    // eslint-disable-next-line
  }, [newSlot]);

  useEffect(() => {
    setCustomGenerateLinkSlots([]);
  }, [slotRange]);

  return (
    <div className="todo-modal">
      <Modal
        getContainer={document.getElementById("root")}
        destroyOnClose
        title={false}
        width={isCalendarView ? "90vw" : 550}
        centered
        open={isTodoModalVisible}
        onCancel={() => {
          onSubmit();
          eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
        }}
        footer={false}
        closeIcon={false}
      >
        {isTodoDataLoading ? (
          <Row className="h-[500px] justify-center items-center w-full">
            <Spin />
          </Row>
        ) : (
          <Row className="w-full">
            <Col span={isCalendarView ? 8 : 24} className="h-full">
              <Col
                className={clsx("overflow-auto mb-[10px] max-h-[86vh]", {
                  "h-[86vh]": isCalendarView,
                })}
              >
                <Row className="items-center justify-between">
                  <Typography.Text className="font-semibold">
                    Scheduling Todo
                  </Typography.Text>
                  {!isCalendarView && (
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        onSubmit();
                        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
                      }}
                    />
                  )}
                </Row>
                <Input
                  className="my-[20px]"
                  placeholder="Todo Name"
                  value={todo.title}
                  onChange={(e) => {
                    setTodo({
                      ...todo,
                      title: e.target.value,
                    });
                  }}
                />
                <Row className="mb-[20px] justify-between">
                  <Row className="">
                    <UnorderedListOutlined className="mr-[16px] flex items-start mt-[4px]" />
                    <Typography.Text className="text-[14px] font-normal leading-[24px]">
                      Subtitle
                    </Typography.Text>
                  </Row>
                  <Col className="w-[314px]">
                    <Input
                      placeholder="Subtitle"
                      value={todo.subtitle}
                      onChange={(e) => {
                        setTodo({
                          ...todo,
                          subtitle: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-[20px] items-center justify-between">
                  <Row className="items-center">
                    <Priority className="mr-[16px]" />
                    <Typography.Text className="text-[14px] font-normal leading-[24px]">
                      Priority{" "}
                    </Typography.Text>
                  </Row>
                  <Select
                    showSearch
                    clearIcon
                    className="w-[314px]"
                    placeholder="Select Priorities"
                    value={todo.priority}
                    onChange={(value) =>
                      setTodo({
                        ...todo,
                        priority: value,
                      })
                    }
                  >
                    {defaultPriorities?.map((priority) => (
                      <Select.Option
                        value={priority.key}
                        key={`${priority.key} Priority`}
                      >
                        {priority.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                <Row className="mb-[20px] items-center justify-between">
                  <Row className="items-center">
                    <ClockCircleOutlined className="mr-[16px]" />
                    <Typography.Text className="text-[14px] font-normal leading-[24px]">
                      Due date{" "}
                    </Typography.Text>
                  </Row>
                  <DatePicker
                    showSearch
                    clearIcon
                    className="w-[314px]"
                    placeholder="Due Date"
                    value={todo?.due_date ? dayjs(todo?.due_date) : null}
                    onChange={(value) =>
                      setTodo({
                        ...todo,
                        due_date: value,
                      })
                    }
                  />
                </Row>

                {isShowMore && (
                  <>
                    <Row className="mb-[20px] items-center justify-between">
                      <Row className="items-center">
                        <TagOutlined className="mr-[16px]" />
                        <Typography.Text className="text-[14px] font-normal leading-[24px]">
                          Category{" "}
                        </Typography.Text>
                      </Row>
                      <div className="w-[314px]">
                        <CategoryDropdown
                          key={todo?.category_id}
                          isNewCategoryAllowed={true}
                          defaultCategoryId={todo?.category_id}
                          onSelectOption={(parentKeys) => {
                            setTodo({
                              ...todo,
                              category_id: parentKeys?.[parentKeys?.length - 1],
                            });
                          }}
                        />
                      </div>
                    </Row>

                    <Row className="mb-[20px] justify-between">
                      <Row className="">
                        <ClockCircleOutlined className="mr-[16px] flex items-start mt-[4px]" />
                        <Typography.Text className="text-[14px] font-normal leading-[24px]">
                          Duration
                        </Typography.Text>
                      </Row>
                      <Col className="w-[314px]">
                        <Select
                          showSearch
                          clearIcon
                          className="w-[114px]"
                          placeholder="Select Duration"
                          defaultValue={"custom"}
                          value={todo?.durationSlots}
                          onSelect={(value) => {
                            setTodo({
                              ...todo,
                              durationSlots: value,
                            });
                            setIsExistingTask(
                              value <= 30 && (todo?.id || todoId) ? true : false,
                            );
                          }}
                        >
                          {durationSlots?.map((item) => (
                            <Select.Option key={item?.key} value={item?.key}>
                              {item?.value}
                            </Select.Option>
                          ))}
                        </Select>
                        {todo?.durationSlots === "custom" && (
                          <>
                            <InputNumber
                              min={1}
                              stringMode
                              value={todo?.duration}
                              onChange={(value) => {
                                setTodo({
                                  ...todo,
                                  duration: value,
                                });
                                setIsExistingTask(
                                  value <= 30 && (todo?.id || todoId) ? true : false,
                                );
                              }}
                              className="ml-2 w-[100px]"
                            />
                            <Typography.Text className="ml-2">Mins</Typography.Text>
                          </>
                        )}
                        {(todo?.id || todoId) && scheduleType == "auto_schedule" && (
                          <div className="w-[314px]">
                            <Row className="items-center mt-[10px]">
                              <Checkbox
                                checked={isExistingTask}
                                onClick={() => {
                                  setIsExistingTask(!isExistingTask);
                                }}
                              />
                              <Typography.Text className="text-[14px] font-normal leading-[24px] ml-[8px]">
                                Add small task on existing time slot
                              </Typography.Text>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="flex-col">
                      <Row className="items-center mb-[20px]">
                        <Union className="fill-black" />
                        <Typography.Text className="ml-2">Subtask</Typography.Text>
                      </Row>
                      <Row className="flex-col">
                        {currentSubTasks?.map((task, index) => (
                          <Row key={task.id} className="items-center mb-[20px]">
                            <Col span={2}>
                              <div
                                className="h-[16px] w-[16px] flex rounded justify-center items-center text-center text-[10px] mr-2  text-[#606369]"
                                style={{
                                  borderRadius: "32px",
                                  border:
                                    "1px solid var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25))",
                                }}
                              >
                                {index + 1}
                              </div>
                            </Col>
                            <Col span={22}>
                              <Input
                                defaultValue={task.title}
                                value={task.title}
                                onChange={(e) =>
                                  handleTextChange(task.id, e.target.value)
                                }
                                addonAfter={
                                  <DeleteOutlined
                                    className="text-[14px] cursor-pointer"
                                    onClick={() => handleDeleteSubtask(task.id)}
                                  />
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                      </Row>
                      <Button
                        type="text"
                        onClick={handleAddSubtask}
                        icon={<PlusOutlined />}
                        className={clsx("text-primary w-[40%] p-0 m-0", {
                          "mb-[20px]": !isSlotsVisible,
                        })}
                      >
                        {" "}
                        Add new subtask
                      </Button>
                    </Row>
                  </>
                )}
                <Button
                  type="link"
                  className="p-0 m-0"
                  onClick={() => {
                    setIsShowMore(!isShowMore);
                  }}
                >
                  {isShowMore ? "Show less" : "Show more"}
                </Button>
                {(todo?.id || todoId) && (
                  <Col>
                    <div className="mt-[20px]">
                      {todoData?.data?.user_scheduling_info?.start_time &&
                        todoData?.data?.user_scheduling_info?.end_time && (
                          <div>
                            <Typography.Text>Current Time Slot</Typography.Text>
                            <Typography.Text className="text-[#606369]">
                              <ClockCircleOutlined className="ml-[26px] mb-[20px]  mr-2" />
                              {getTime(todoData?.data?.user_scheduling_info?.start_time)}
                            </Typography.Text>
                          </div>
                        )}
                      {todoData?.data?.guest_scheduling_info?.event_data?.start_time &&
                        todoData?.data?.guest_scheduling_info?.event_data?.end_time && (
                          <div>
                            <Typography.Text>Current Time Slot</Typography.Text>
                            <Typography.Text className="text-[#606369]">
                              <ClockCircleOutlined className="ml-[26px] mb-[20px]  mr-2" />
                              {getTime(
                                todoData?.data?.guest_scheduling_info?.event_data
                                  ?.start_time,
                              )}
                            </Typography.Text>
                          </div>
                        )}
                    </div>
                  </Col>
                )}
                {isCalendarView && (
                  <>
                    <Divider className="m-0 mb-[20px]" />
                    <Radio.Group
                      value={scheduleType}
                      onChange={(e) => {
                        setScheduleType(e.target.value);
                        if (e.target.value != "manually_schedule") {
                          setManualSlot({
                            date: null,
                            startTime: null,
                            endTime: null,
                          });
                        }
                      }}
                      className="mb-[20px]"
                    >
                      <Radio.Button
                        value="manually_schedule"
                        style={{
                          borderRadius: 0,
                          backgroundColor:
                            scheduleType === "manually_schedule" ? "#4385C2" : "white",
                          color:
                            scheduleType === "manually_schedule" ? "white" : "#4385C2",
                        }}
                      >
                        Manually Schedule
                      </Radio.Button>
                      <Radio.Button
                        value="auto_schedule"
                        style={{
                          borderRadius: 0,
                          backgroundColor:
                            scheduleType === "auto_schedule" ? "#4385C2" : "white",
                          color: scheduleType === "auto_schedule" ? "white" : "#4385C2",
                        }}
                      >
                        Automatically Schedule
                      </Radio.Button>
                    </Radio.Group>

                    <Divider className="m-0 mb-[20px]" />

                    {scheduleType == "manually_schedule" && (
                      <>
                        <Row className="mb-[16px] items-center justify-between">
                          <Typography.Text>
                            <CalendarOutlined className="mr-4" />
                            Date
                          </Typography.Text>
                          <DatePicker
                            className="w-[70%]"
                            value={manualSlot.date}
                            onChange={(value) =>
                              setManualSlot({ ...manualSlot, date: value })
                            }
                          />
                        </Row>

                        <Row className="mb-[16px] items-center justify-between">
                          <Typography.Text>
                            <ClockCircleOutlined className="mr-4" />
                            Start Time
                          </Typography.Text>
                          <div className="flex w-[70%]">
                            <Button
                              type="text"
                              onClick={() => {
                                setManualSlot({
                                  ...manualSlot,
                                  startTime: dayjs(
                                    manualSlot.startTime || dayjs(),
                                  ).subtract(5, "minutes"),
                                });
                              }}
                            >
                              -5m
                            </Button>
                            <DatePicker.TimePicker
                              format={format}
                              className="w-[70%]"
                              value={manualSlot.startTime}
                              onChange={(value) =>
                                setManualSlot({ ...manualSlot, startTime: value })
                              }
                            />
                            <Button
                              type="text"
                              onClick={() => {
                                setManualSlot({
                                  ...manualSlot,
                                  startTime: dayjs(manualSlot.startTime || dayjs()).add(
                                    5,
                                    "minutes",
                                  ),
                                });
                              }}
                            >
                              +5m
                            </Button>
                          </div>
                        </Row>

                        <Row className="mb-[16px] items-center justify-between">
                          <Typography.Text>
                            <ClockCircleOutlined className="mr-4" />
                            End Time
                          </Typography.Text>
                          <div className="flex w-[70%]">
                            <Button
                              type="text"
                              onClick={() => {
                                setManualSlot({
                                  ...manualSlot,
                                  endTime: dayjs(manualSlot.endTime || dayjs()).subtract(
                                    5,
                                    "minutes",
                                  ),
                                });
                              }}
                            >
                              -5m
                            </Button>
                            <DatePicker.TimePicker
                              format={format}
                              className="w-[70%]"
                              value={manualSlot.endTime}
                              onChange={(value) =>
                                setManualSlot({ ...manualSlot, endTime: value })
                              }
                            />
                            <Button
                              type="text"
                              onClick={() => {
                                setManualSlot({
                                  ...manualSlot,
                                  endTime: dayjs(manualSlot.endTime || dayjs()).add(
                                    5,
                                    "minutes",
                                  ),
                                });
                              }}
                            >
                              +5m
                            </Button>
                          </div>
                        </Row>
                      </>
                    )}
                  </>
                )}
                {scheduleType == "auto_schedule" && (
                  <>
                    {!isExistingTask && (
                      <Row className="mb-[20px] items-center justify-between">
                        <Row className="items-center">
                          <Zone className="mr-[16px]" />
                          <Typography.Text className="text-[14px] font-normal leading-[24px]">
                            Zone
                          </Typography.Text>
                        </Row>
                        <div className="w-[314px]">
                          <ZoneDropdown
                            value={todo?.zone_id}
                            onChange={(value) => setTodo({ ...todo, zone_id: value })}
                            isNewZoneAllowed={true}
                          />
                        </div>
                      </Row>
                    )}

                    {(todo?.id || todoId) && !isExistingTask && (
                      <>
                        <Row className="items-center justify-between mb-[20px]">
                          <Row className="items-center">
                            <SelectOutlined className="mr-[16px]" />
                            <Typography.Text>Select time</Typography.Text>
                          </Row>
                          <Row>
                            <Radio.Group
                              defaultValue={todo?.scheduling_type}
                              value={todo?.scheduling_type}
                              onChange={(e) => {
                                setTodo({ ...todo, scheduling_type: e.target.value });
                              }}
                            >
                              <Radio.Button
                                value="user"
                                style={{
                                  borderRadius: 0,
                                  backgroundColor:
                                    todo?.scheduling_type === "user"
                                      ? "#4385C2"
                                      : "white",
                                  color:
                                    todo?.scheduling_type === "user"
                                      ? "white"
                                      : "#4385C2",
                                }}
                              >
                                I will choose
                              </Radio.Button>
                              <Radio.Button
                                value="guest"
                                style={{
                                  borderRadius: 0,
                                  backgroundColor:
                                    todo?.scheduling_type === "guest"
                                      ? "#4385C2"
                                      : "white",
                                  color:
                                    todo?.scheduling_type === "guest"
                                      ? "white"
                                      : "#4385C2",
                                }}
                              >
                                Let the guest choose
                              </Radio.Button>
                            </Radio.Group>
                          </Row>
                        </Row>
                      </>
                    )}

                    {(todo?.id || todoId) && isExistingTask && (
                      <>
                        <Radio.Group
                          value={existingTaskSlotType}
                          onChange={(e) => {
                            setExistingTaskSlotType(e.target.value);
                          }}
                          className="mb-[20px]"
                        >
                          <Radio.Button
                            value="old_slot"
                            style={{
                              borderRadius: 0,
                              backgroundColor:
                                existingTaskSlotType === "old_slot" ? "#4385C2" : "white",
                              color:
                                existingTaskSlotType === "old_slot" ? "white" : "#4385C2",
                            }}
                          >
                            Pick existing slot
                          </Radio.Button>
                          <Radio.Button
                            value="new_slot"
                            style={{
                              borderRadius: 0,
                              backgroundColor:
                                existingTaskSlotType === "new_slot" ? "#4385C2" : "white",
                              color:
                                existingTaskSlotType === "new_slot" ? "white" : "#4385C2",
                            }}
                          >
                            Create new slot
                          </Radio.Button>
                        </Radio.Group>
                        {existingTaskSlotType === "old_slot" ? (
                          <>
                            {isTaskBlockerLoading ? (
                              <Row className="justify-center items-center w-full">
                                <Spin />
                              </Row>
                            ) : recommendedSlots?.length > 0 ? (
                              <DaySlots
                                slotsData={recommendedSlots}
                                isLoading={isTaskBlockerLoading}
                                slotRange={slotRange}
                                slotType={"select"}
                                slotsSide={"user"}
                                onChange={(value) => {
                                  setTodo({
                                    ...todo,
                                    user_scheduling_info: value?.time,
                                    event_id: value?.id,
                                  });
                                }}
                                isRecommended={true}
                              />
                            ) : (
                              <Empty className="mb-[20px]" />
                            )}
                          </>
                        ) : (
                          <>
                            <Input
                              placeholder="Slot name"
                              className="mb-[16px]"
                              autoFocus
                              value={newSlot?.text}
                              onChange={(e) =>
                                setNewSlot({ ...newSlot, text: e.target.value })
                              }
                            />
                            <Row className="mb-[16px] items-center justify-between">
                              <Typography.Text>
                                <CalendarOutlined className="mr-4" />
                                Date
                              </Typography.Text>
                              <DatePicker
                                className="w-[70%]"
                                value={newSlot.date}
                                onChange={(value) =>
                                  setNewSlot({ ...newSlot, date: value })
                                }
                              />
                            </Row>
                            <Row className="mb-[16px] items-center justify-between">
                              <Typography.Text>
                                <ClockCircleOutlined className="mr-4" />
                                Start Time
                              </Typography.Text>
                              <DatePicker.TimePicker
                                format={format}
                                className="w-[70%]"
                                value={newSlot.startTime}
                                onChange={(value) =>
                                  setNewSlot({ ...newSlot, startTime: value })
                                }
                              />
                            </Row>
                            <Row className="mb-[16px] items-center justify-between">
                              <Typography.Text>
                                <ClockCircleOutlined className="mr-4" />
                                End Time
                              </Typography.Text>
                              <DatePicker.TimePicker
                                format={format}
                                className="w-[70%]"
                                value={newSlot.endTime}
                                onChange={(value) => {
                                  setNewSlot({ ...newSlot, endTime: value });
                                }}
                              />
                            </Row>
                            <Row className="mb-[16px] items-center justify-between">
                              <Row className="items-center">
                                <CalendarOutlined className="mr-[16px]" />
                                <Typography.Text className="text-[14px] font-normal leading-[24px]">
                                  Calendar{" "}
                                </Typography.Text>
                              </Row>
                              <Select
                                showSearch
                                clearIcon
                                className="w-[70%]"
                                placeholder="Select Calendar"
                                defaultValue={defaultSelectedAddCalendarValue}
                                onSelect={(value) => {
                                  setNewSlot({
                                    ...newSlot,
                                    calendarId: value,
                                  });
                                }}
                              >
                                {userData?.data?.subcalendars_with_write_access?.map(
                                  (calendar) => (
                                    <Select.Option value={calendar.id} key={calendar.id}>
                                      {calendar?.calendar_title +
                                        " / " +
                                        calendar?.subcalendar_title}
                                    </Select.Option>
                                  ),
                                )}
                              </Select>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                    {(todo?.id || todoId) && !isExistingTask && (
                      <div className="flex-col">
                        <Divider className="mb-[20px]" />
                        <Row className="justify-between">
                          <Typography.Text className="text-[16px] font-semibold leading-[24px]">
                            {todo?.scheduling_type === "user"
                              ? "Select time slot"
                              : "Allow time slot"}
                          </Typography.Text>
                        </Row>
                        <Row className="items-center justify-between my-[20px]">
                          <Row className="flex-col items-start">
                            <Typography.Text className="w-full mb-2">
                              Showing time slot
                            </Typography.Text>
                            <Radio.Group
                              className="w-full"
                              value={slotRange}
                              onChange={(e) => setSlotRange(e.target.value)}
                            >
                              <Radio.Button value="current_week">
                                Current Week
                              </Radio.Button>
                              <Radio.Button value="next_week">Next Week</Radio.Button>
                              <Radio.Button value="after_next_week">
                                After Next Week
                              </Radio.Button>
                            </Radio.Group>
                            {!todo?.isSlotOffered &&
                              todo?.scheduling_type === "guest" && (
                                <Button
                                  type="link"
                                  className="p-0 m-0 ml-2 mt-2"
                                  onClick={() =>
                                    setTodo({
                                      ...todo,
                                      isSlotOffered: !todo?.isSlotOffered,
                                    })
                                  }
                                >
                                  {!todo?.isSlotOffered ? "Show offered" : "Hide Offered"}
                                </Button>
                              )}
                          </Row>
                        </Row>
                        {((todo?.isSlotOffered && todo?.scheduling_type === "guest") ||
                          todo?.scheduling_type === "user") && (
                          <DaySlots
                            slotsData={todoSlotsData}
                            isLoading={isTodoSlotsLoading}
                            slotRange={slotRange}
                            slotType={
                              todo?.scheduling_type === "guest" ? "deselect" : "select"
                            }
                            slotsSide={"user"}
                            onChange={(value) =>
                              todo?.scheduling_type === "user"
                                ? setTodo({
                                    ...todo,
                                    user_scheduling_info: value,
                                  })
                                : setCustomGenerateLinkSlots(value)
                            }
                          />
                        )}
                      </div>
                    )}
                    {isSlotsVisible &&
                      todo?.scheduling_type === "guest" &&
                      !isExistingTask && (
                        <div className="flex-col">
                          <Divider className="my-[20px]" />
                          <div className={isNewGuestLink ? "opacity-[0.5]" : ""}>
                            <Radio.Group
                              defaultValue={todo.scheduling_link_type ?? "copy_link"}
                              value={todo.scheduling_link_type}
                              onChange={(e) =>
                                setTodo({ ...todo, scheduling_link_type: e.target.value })
                              }
                              disabled={isNewGuestLink}
                            >
                              <Radio.Button
                                value="send_link"
                                style={{
                                  borderRadius: 0,
                                  backgroundColor:
                                    todo?.scheduling_link_type === "send_link"
                                      ? "#4385C2"
                                      : "white",
                                  color:
                                    todo?.scheduling_link_type === "send_link"
                                      ? "white"
                                      : "black",
                                }}
                              >
                                Generate scheduling link email
                              </Radio.Button>
                              <Radio.Button
                                value="copy_link"
                                style={{
                                  borderRadius: 0,
                                  backgroundColor:
                                    todo?.scheduling_link_type === "copy_link"
                                      ? "#4385C2"
                                      : "white",
                                  color:
                                    todo?.scheduling_link_type === "copy_link"
                                      ? "white"
                                      : "black",
                                }}
                              >
                                Copy scheduling link
                              </Radio.Button>
                            </Radio.Group>
                            {todo?.scheduling_link_type === "copy_link" && (
                              <Col className="my-[20px]">
                                <Typography.Text className="text-[12px] text-[#606369] leading-[24px]">
                                  Share this event link with the guest you want in the
                                  event to choose time
                                </Typography.Text>
                                <Input
                                  defaultValue={
                                    !isNewGuestLink
                                      ? todo?.guest_scheduling_info?.scheduling_link
                                      : ""
                                  }
                                  value={
                                    !isNewGuestLink
                                      ? todo?.guest_scheduling_info?.scheduling_link
                                      : ""
                                  }
                                  disabled={isNewGuestLink}
                                  addonAfter={
                                    <CopyOutlined
                                      className="text-[14px] cursor-pointer"
                                      onClick={() =>
                                        copyTextToClipboard(
                                          !isNewGuestLink
                                            ? todo?.guest_scheduling_info?.scheduling_link
                                            : "",
                                        )
                                      }
                                    />
                                  }
                                />
                              </Col>
                            )}
                            {todo?.scheduling_link_type === "send_link" && (
                              <Row className="my-[20px] items-center justify-between">
                                <Row className="items-center">
                                  <UserAddOutlined className="mr-[16px]" />
                                  <Typography.Text className="text-[14px] font-normal leading-[24px]">
                                    Add Guest{" "}
                                  </Typography.Text>
                                </Row>
                                <Input
                                  placeholder={"Mail Id"}
                                  className="w-[320px]"
                                  value={todo?.guest_email}
                                  onChange={(e) =>
                                    setTodo({ ...todo, guest_email: e.target.value })
                                  }
                                />
                              </Row>
                            )}
                          </div>
                        </div>
                      )}
                  </>
                )}
              </Col>
              {isExistingTask && (
                <Row
                  key="isExistingTask"
                  className="flex-row-reverse w-full items-center"
                >
                  <Button
                    type="primary"
                    className="ml-[16px] bg-primaryDark"
                    onClick={() => {
                      todoId || todo?.id
                        ? handleScheduleTodoInSlot()
                        : handleSaveTodo(true);
                    }}
                    loading={isUpdateTodoLoading || isScheduleTodoInSlotLoading}
                  >
                    {/* {todo?.scheduling_status === "not_scheduled" ? "Schedule" : "Save"} */}
                    Save
                  </Button>
                </Row>
              )}
              {!isExistingTask && (
                <>
                  {isCalendarView ? (
                    <Row key="with_time" className="flex-row-reverse w-full items-center">
                      <Row className="">
                        {/*{(todo?.id || todoId) && (*/}
                        {/*  <Button*/}
                        {/*    key="auto-schedule"*/}
                        {/*    type="primary"*/}
                        {/*    className="ml-[16px] bg-primaryDark"*/}
                        {/*    onClick={handleAutoScheduleTodo}*/}
                        {/*    loading={isAutoScheduleLoading}*/}
                        {/*  >*/}
                        {/*    Auto Schedule*/}
                        {/*  </Button>*/}
                        {/*)}*/}
                      </Row>
                      {todo?.scheduling_type === "guest" ? (
                        <Row className="items-center">
                          <Button
                            type="primary"
                            className="ml-[16px] bg-primaryDark"
                            onClick={async () => {
                              if (todo?.scheduling_link_type === "copy_link") {
                                await handleGenerateLink();
                                eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
                              } else {
                                await handleGenerateLink();
                                eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
                                setIsCopyModalVisible(true);
                              }
                            }}
                            loading={isGenerateLinkLoading}
                          >
                            {todo?.scheduling_link_type === "copy_link"
                              ? isNewGuestLink
                                ? "Generate link"
                                : "Update Link"
                              : isNewGuestLink
                              ? "Send scheduling link"
                              : "Generate scheduling email"}
                          </Button>
                        </Row>
                      ) : (
                        <Button
                          type="primary"
                          className="ml-[16px] bg-primaryDark"
                          onClick={() =>
                            todoId || todo?.id
                              ? handleUpdateTodo(true)
                              : handleSaveTodo(true)
                          }
                          loading={isUpdateTodoLoading}
                        >
                          {/* {todo?.scheduling_status === "not_scheduled"
                            ? "Schedule"
                            : "Save"} */}
                          Save
                        </Button>
                      )}
                    </Row>
                  ) : (
                    <Row
                      key="without_time"
                      className="flex-row-reverse items-center w-full"
                    >
                      {/*{(todo?.id || todoId) && (*/}
                      {/*  <Button*/}
                      {/*    key="auto-schedule"*/}
                      {/*    type="primary"*/}
                      {/*    className="ml-[16px] bg-primaryDark"*/}
                      {/*    onClick={handleAutoScheduleTodo}*/}
                      {/*    loading={isAutoScheduleLoading}*/}
                      {/*  >*/}
                      {/*    Auto Schedule*/}
                      {/*  </Button>*/}
                      {/*)}*/}
                      <Button
                        key="assign_slot"
                        type="primary"
                        className="bg-primaryDark ml-[16px]"
                        onClick={handleAssignTimeSlots}
                        loading={isSaveTodoLoading || isUpdateTodoLoading}
                      >
                        Assign time slot
                      </Button>
                      <Button
                        key="start_timer"
                        onClick={() => {
                          handleTodoStartTimer();
                        }}
                        disabled={isTodoTimerRunning || isRunning}
                      >
                        Start Timer
                      </Button>
                      <Button
                        key="save"
                        className="mr-[16px]"
                        onClick={() =>
                          todoId || todo?.id
                            ? handleUpdateTodo(true)
                            : handleSaveTodo(true)
                        }
                        loading={isSaveTodoLoading}
                      >
                        Save
                      </Button>
                    </Row>
                  )}
                </>
              )}
            </Col>
            {isCalendarView ? (
              <>
                <Col span={16}>
                  <SlotCalendarView
                    manualSlot={manualSlot}
                    selectedSlot={todo?.user_scheduling_info}
                    title={
                      scheduleType == "auto_schedule" && isExistingTask
                        ? newSlot?.text || ""
                        : todo?.title
                    }
                    zone_id={todo?.zone_id}
                    showCloseIcon={!isFullPage}
                    onCancel={() => {
                      onSubmit();
                      eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
                    }}
                    onSlotChange={(value) => {
                      if (scheduleType === "manually_schedule") {
                        if (value.action == "click") {
                          setManualSlot({
                            date: dayjs(value?.start),
                            startTime: dayjs(value?.start),
                            endTime: dayjs(value?.start).add(15, "minutes"),
                          });
                        } else {
                          setManualSlot({
                            date: dayjs(value?.start),
                            startTime: dayjs(value?.start),
                            endTime: dayjs(value?.end),
                          });
                        }
                      }
                      if (isExistingTask && existingTaskSlotType === "new_slot") {
                        setNewSlot({
                          date: dayjs(value?.start),
                          startTime: dayjs(value?.start),
                          endTime: dayjs(value?.end),
                        });
                      }
                    }}
                  />
                </Col>
              </>
            ) : (
              <div
              // span={12}
              // className={clsx("flex-col p-[10px] relative px-[20px] h-[10px]", {
              //   "mb-[20px]": isSlotsVisible,
              // })}
              />
            )}
          </Row>
        )}
      </Modal>
      <Modal
        getContainer={document.getElementById("root")}
        destroyOnClose
        title={"Sending event scheduling mail"}
        width={500}
        centered
        open={isCopyModalVisible}
        onCancel={() => {
          setIsCopyModalVisible(false);
        }}
        footer={[
          <Button
            key={"submit"}
            type="primary"
            className="bg-primaryDark"
            onClick={handleSendSchedulingLink}
            loading={isSendSchedulingLinkLoading}
          >
            Send scheduling link
          </Button>,
        ]}
      >
        <Typography.Text className="text-[12px] text-[#606369] leading-[24px]">
          Your Email
        </Typography.Text>
        <Select
          showSearch
          clearIcon
          className="w-full mb-[10px]"
          placeholder="Select Calendar"
          defaultValue={userData?.data?.email}
          onSelect={(value) => {
            setTodo({
              ...todo,
              behalf_user_email: value,
            });
          }}
        >
          {userData?.data?.calendars?.map((calendar) => (
            <Select.Option value={calendar?.title} key={calendar?.title}>
              {calendar?.title}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="text-[12px] text-[#606369] mt-[20px] leading-[24px]">
          Guest’s Email
        </Typography.Text>
        <Input
          placeholder={"Mail Id"}
          className="w-full mb-[20px]"
          value={todo?.guest_email}
          onChange={(e) => setTodo({ ...todo, guest_email: e.target.value })}
        />
        <Row className="flex-col p-[12px] rounded-[2px] bg-[#F8F8F8]">
          <Typography.Text className="text-[12px] leading-[22px] mb-[10px]">
            Subject - {todo?.guest_scheduling_info?.email_subject || "Empty"}
          </Typography.Text>
          <Typography.Text className="text-[12px] text-[#606369] leading-[22px]">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(todo?.guest_scheduling_info?.email_body),
              }}
            />
          </Typography.Text>
        </Row>
      </Modal>
    </div>
  );
};

export default TodoModal;
